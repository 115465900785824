<template>
  <div class="flex flex-col items-center w-full h-full">
    <div class="flex flex-col w-full h-full overflow-auto">
      <div
        class="flex flex-col justify-center flex-shrink-0 w-full p-2 my-1 text-gray-800 bg-white shadow"
      >
        <div class="flex flex-col flex-1 p-2 pl-5 space-y-2 text-left">
          <div class="flex flex-col py-3 space-y-3 text-lg">
            <h1 class="font-extrabold">Cara Verifikasi Email</h1>
            <p>
              Verifikasi email untuk memastikan email anda tidak disalah gunakan
              oleh orang lain, berikut adalah step step untuk memverifikasi
              email anda
            </p>

            <h2 class="pt-5 font-bold">
              1. Registrasi Akun Baru
            </h2>
            <p>
              Setelah melakukan registrasi akun baru di
              <a
                href="https://checkin.pointhub.net/signup"
                class="text-blue-800"
                >https://checkin.pointhub.net/signup</a
              >
              anda akan menerima email untuk verifikasi akun email yang anda
              gunakan
            </p>

            <h2 class="pt-5 font-bold">
              2. Buka email dan klik link verifikasi
            </h2>
            <img src="/img/guides/how-to-verify-email/2.1.png" alt="" />
            <br />
            <img src="/img/guides/how-to-verify-email/2.2.png" alt="" />

            <h2 class="pt-5 font-bold">
              3. Akun email telah terverifikasi
            </h2>
            <p>
              Setelah akun berhasil diverifikasi anda sudah bisa menggunakan
              email anda untuk melakukan login ke dalam aplikasi
            </p>
            <img
              src="/img/guides/how-to-verify-email/3.png"
              class="shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
